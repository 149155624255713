// import apkSrc from "../assets/download/29mall-beta.apk";

export const commingSoon = () => {
    window.alert("Coming Soon!");
};

const APP_TYPE = {
    googlestore: "https://play.google.com/store/apps/details?id=com.acts.acts29mall",
    appstore: "https://apps.apple.com/us/app/29mall/id6504643864",
};
export const downloadApk = () => {
    window.open(process.env.PUBLIC_URL + "/apk/29mall-beta.apk");
};

export const downloadXlsx = () => {
    window.open(process.env.PUBLIC_URL + "/form/29 MALL_입점신청서양식.xlsx");
};

export const onClickAppDownLink = (type) => {
    window.open(APP_TYPE[type]);
};
